<template>
  <div>
    <toolbar></toolbar>
    <v-container class="pb-0">
      <!-- region Home -->
      <div class="pt-7 pb-md-10 pb-lg-3 pb-3">
        <div class="text-center mb-md-15">
          <p class="text-button">Bienvenido a seoferta</p>
          <h2 class="mb-1">¡Anímate a crear tu propio espacio en línea!</h2>
          <p class="text-body-1 px-sm-10"> SeOferta, una plataforma con las herramientas para iniciar, ejecutar y hacer
            crecer tu negocio.</p>
        </div>
        <v-row>
          <v-col cols="12" md="6">
            <v-list class="pb-5 px-sm-10 px-md-0">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary" large> mdi-web</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Inicia tu negocio en línea en sólo 5 minutos.
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary" large>mdi-share-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Comparte los productos y servicios que brindas con sólo un clic.
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary" large> mdi-whatsapp</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Recibe y gestiona los pedidos organizadamente en tu WhatsApp sin intermediarios.
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary" large> mdi-calendar-clock</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Tu negocio disponible 24/7 y sin costo de mantenimiento.
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <div class="d-flex flex-wrap justify-center mb-5">
              <v-btn
                  :to="{name: 'business'}"
                  class="text-none text-body-1 font-weight-medium mx-2 mb-3"
                  color="primary"
                  depressed
                  large
                  rounded>
                Ver negocios en SeOferta
              </v-btn>
              <sign-up class="mx-1" componentName="RegisterHome"></sign-up>
            </div>

          </v-col>
          <v-col class="pb-0" cols="12" md="6">
            <v-img :src="require('../assets/vector.jpg')"
                   contain
                   max-height="470">
            </v-img>
          </v-col>
        </v-row>
      </div>
      <!-- endregion-->
    </v-container>

    <!-- region Point -->
    <v-sheet class="py-10" color="primaryDark" dark>
      <v-container class="text-center">
        <v-row>
          <v-col cols="12" md="4">
            <div class="pa-5">
              <v-avatar color="white"
                        size="62">
                <span class="text-h5 font-weight-bold" style="color: #008081">1</span>
              </v-avatar>
              <h2 class="font-weight-medium py-5">Hazte miembro</h2>
              <p class="text-body-1">Únete a SeOferta y dale vida a tus productos y servicios creando un espacio en
                línea
                para tu negocio.</p>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pa-5">
              <v-avatar
                  color="white"
                  size="62">
                <span class="text-h5 font-weight-bold" style="color: #008081">2</span>
              </v-avatar>
              <h2 class="font-weight-medium py-5">Anuncia tu negocio</h2>
              <p class="text-body-1">Exhibe tus productos y servicios a clientes de cualquier lugar desde un solo
                canal.</p>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pa-5">
              <v-avatar color="white"
                        size="62">
                <span class="text-h5 font-weight-bold" style="color: #008081">3</span>
              </v-avatar>
              <h2 class="font-weight-medium py-5">Gana dinero </h2>
              <p class="text-body-1">Gestiona los pedidos sin complicaciones desde cualquier parte. Aumenta tu alcance y
                con ello tus ingresos.</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- endregion-->

    <!-- region Example Business -->
    <v-sheet class="py-10 px-sm-10">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-img :src="require('../assets/example-bussiness.svg')"
                   contain max-height="400">
            </v-img>
          </v-col>
          <v-col cols="12" md="6">
            <div class="pr-md-10">
              <div class="text-center text-md-left">
                <p class="text-button">Ejemplos de negocios</p>
                <h2 class="mb-1">Así se verá tu negocio en línea</h2>
                <p class="text-body-1 px-10 px-md-0">Explora ejemplos de páginas de negocios creadas en SeOferta, no
                  importa lo que vendas o servicio que prestes, inspírate y crea tu negocio en línea.</p>
              </div>
              <v-list class="mt-5">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-storefront-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <span class="text-body-1 font-weight-medium pb-1">Tienda de ropa</span>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn class="text-none text-subtitle-2" depressed href="https://seoferta.net/moda-cubana-demo" link
                           rounded target="_blank">
                      <span style="color: #008081">Ver ejemplo</span>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-storefront-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <span class="text-body-1 font-weight-medium pb-1">Pizzería</span>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn class="text-none text-subtitle-2" depressed href="https://seoferta.net/pizzeria-hermes-demo"
                           link
                           rounded target="_blank">
                      <span style="color: #008081">Ver ejemplo</span>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-storefront-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <span class="text-body-1 font-weight-medium pb-1">Negocio de tecnología</span>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn class="text-none text-subtitle-2" depressed href="https://seoferta.net/gama-alta-demo" link
                           rounded target="_blank">
                      <span style="color: #008081">Ver ejemplo</span>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </div>

          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- endregion -->

    <!-- region Support -->
    <v-sheet class="py-5 px-sm-10" color="greyPrimary">
      <v-container class="text-center text-md-left">
        <v-row>
          <v-col cols="12" md="6">
            <div class="pt-10 pl-md-10">
              <p class="text-button">Soporte 24/7</p>
              <h2 class="mb-1">Obtén la ayuda que necesitas</h2>
              <p class="pb-5 text-body-1 px-10 px-md-0">Te ayudamos en cada paso. En video, te mostramos cómo
                <strong>crear un
                  negocio en línea con
                  SeOferta</strong>, rápido y sencillo.</p>
              <v-dialog v-model="dialog"
                        fullscreen>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="text-none text-body-1 font-weight-medium"
                         color="primary"
                         depressed
                         large
                         rounded
                         v-bind="attrs"
                         v-on="on">
                    <v-icon left>mdi-play</v-icon>
                    Ver video
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar color="white"
                             flat>
                    <v-spacer></v-spacer>
                    <v-btn color="primary"
                           icon
                           @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <div class="iframe-container">
                    <iframe v-if="dialog === true"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen frameborder="0"
                            height="315" src="https://www.youtube.com/embed/zF-t76bwDTI"
                            title="YouTube video player"
                            width="560"></iframe>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-img :src="require('../assets/support-animate.svg')"
                   contain
                   max-height="300">
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- endregion -->

    <!-- Slide Business -->
    <v-sheet class="pt-10"
             color="dark">
      <v-container>
        <div class="mx-sm-10 text-center">
          <p class="text-button">Negocios en SeOferta</p>
          <h2 class="mb-1">¿Quiénes están con nosotros?</h2>
          <p class="text-body-1 px-10 px-md-0 pb-5">Explora y súmate a la variedad de negocios disponibles en
            SeOferta</p>
          <v-carousel hide-delimiters>
            <template v-for="(item, index) in promotedBusiness">
              <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1"
                               :key="index">
                <v-row class="flex-nowrap" style="height:100%">
                  <template v-for="(n,i) in columns">
                    <template v-if="(+index + i) < promotedBusiness.length">
                      <v-col :key="i">
                        <v-sheet v-if="(+index + i) < promotedBusiness.length"
                                 height="100%"
                        >
                          <v-row align="center"
                                 class="fill-height"
                                 justify="center"
                          >
                            <v-hover v-slot="{ hover }">
                              <v-card :class="{ 'on-hover': hover }"
                                      :elevation="hover ? 5: 2"
                                      class="nameBusiness rounded-t-xl rounded-b-xl pa-0 text-left"
                                      target="_blank"
                                      width="320px">
                                <div class="px-6 py-4">
                                  <v-list class="py-0" flat>
                                    <v-list-item :href="getBusinessUrl(promotedBusiness[+index + i].slug)"
                                                 :ripple="false"
                                                 target="_blank"
                                                 class="px-0">
                                      <v-list-item-avatar color="greyPrimary" size="55">
                                        <v-img v-if="promotedBusiness[+index + i].logo.thumb !== ''"
                                               :src="promotedBusiness[+index + i].logo.thumb"></v-img>
                                        <span v-else style="color: #008081; font-size: 32px; font-weight: bold">{{
                                            firstChar(promotedBusiness[+index + i].name)
                                          }}</span>
                                      </v-list-item-avatar>
                                      <v-list-item-content class="my-link-hover">
                                        <h3>{{ promotedBusiness[+index + i].name }}</h3>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>

                                  <p class="text-body-2 pt-4 mb-2" style="height: 70px">
                                    {{ promotedBusiness[+index + i].description | truncate(85, ' ...') }}</p>
                                  <div class="d-flex">
                                    <div v-if="promotedBusiness[+index + i].city !== ''"
                                         class="text-caption font-weight-medium mr-1"
                                         color="greyPrimary"
                                         small>
                                      En {{ promotedBusiness[+index + i].city }}
                                    </div>
                                    <v-divider class="mx-2" vertical></v-divider>
                                    <div v-if="promotedBusiness[+index + i].category !== ''"
                                         class="text-caption font-weight-medium mr-1"
                                         outlined
                                         small>
                                      <span style="color: #00999a">{{ promotedBusiness[+index + i].category }}</span>
                                    </div>
                                  </div>

                                </div>
                                <v-img
                                    :src="promotedBusiness[+index + i].cover.path !== '' ? promotedBusiness[+index + i].cover.path : require('@/assets/cover.jpg') "
                                    max-height="200" min-height="200px">
                                </v-img>
                              </v-card>
                            </v-hover>
                          </v-row>
                        </v-sheet>
                      </v-col>
                    </template>
                  </template>
                </v-row>
              </v-carousel-item>
            </template>
          </v-carousel>
          <v-btn :to="{name: 'business'}"
                 class="text-none text-body-1 font-weight-medium mr-3 mb-10"
                 color="primary"
                 depressed
                 large
                 rounded>
            Ver todos los negocios
          </v-btn>
        </div>
      </v-container>
    </v-sheet>

    <v-sheet class="pt-10 pb-16 text-center" color="greyPrimary">
      <v-container>
        <p class="text-center text-button">Preguntas Frecuentes</p>
        <h2 class="text-center mb-1">¿Tienes alguna pregunta sobre SeOferta?</h2>
        <p class="text-body-1 px-10 px-md-0 pb-5">Encuentra las respuestas a las preguntas más comunes a
          continuación. </p>

        <div class="px-10">
          <v-expansion-panels flat>
            <v-expansion-panel style="background-color: #f5f5f7"
            >
              <v-expansion-panel-header class="font-weight-bold">
                <h3 class="font-weight-medium pr-2">¿Qué es SeOferta?</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-left">
                SeOferta es una plataforma gratuita de comercio electrónico que ayuda a pequeños y grandes negocios a
                crear su catálogo, menú o tienda en línea.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider class="my-3"></v-divider>
          <v-expansion-panels flat>
            <v-expansion-panel style="background-color: #f5f5f7"
            >
              <v-expansion-panel-header class="font-weight-bold">
                <h3 class="font-weight-medium pr-2">¿Cuánto cuesta SeOferta?</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-left">
                SeOferta es 100% gratis. Sin pagos mensuales ni comisiones sobre las ventas.
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>
          <v-divider class="my-3"></v-divider>
          <v-expansion-panels flat>
            <v-expansion-panel style="background-color: #f5f5f7"
            >
              <v-expansion-panel-header class="font-weight-bold">
                <h3 class="font-weight-medium pr-2"> ¿Qué tipo de negocios usan SeOferta?</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-left">
                Seoferta se adapta a cualquier tipo de negocio: venta de bienes y servicios, menú de restaurantes, renta
                de habitaciones; negocios grandes y pequeños. En la sección <a class="text-decoration-none" href="/Business">Negocios Seoferta</a> encontrarás
                negocios
                creados con SeOferta.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider class="my-3"></v-divider>
          <v-expansion-panels flat>
            <v-expansion-panel style="background-color: #f5f5f7"
            >
              <v-expansion-panel-header >
                <h3 class="font-weight-medium pr-2">¿Cómo puedo empezar a usar los servicios de SeOferta?</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-left">
                <p>¡Fácil! Solo debes registrarte en la página y empezar a crear tus productos o servicios en línea.</p>
                <p>Crea tu negocio en línea ahora</p>
                <sign-up class="mx-1" componentName="RegisterHome"></sign-up>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider class="my-3"></v-divider>
          <v-expansion-panels flat>
            <v-expansion-panel style="background-color: #f5f5f7"
            >
              <v-expansion-panel-header class="font-weight-bold">
                <h3 class="font-weight-medium pr-2"> ¿Cómo vendo productos o servicios en mi página de negocio en línea?</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-left">
                <p>Tus clientes no pueden tocar ni sentir los productos que vendes en tu tienda en línea. Por eso debes
                  centrarte en ofrecerles una visión clara y veraz de tus productos con descripciones precisas de los
                  mismos.</p>
                <p>Tienes que crear esa confianza en tu negocio, haciendo que tus nuevos clientes potenciales estén
                  dispuestos a visitarte o hacer pedidos en línea.</p>
                <p>Céntrate en la comercialización de tu negocio en línea y de tus productos en redes sociales. También,
                  en
                  tu local poniendo los códigos QR que generamos especialmente para tu negocio en línea.</p>
                <p>Cuando se trata de SeOferta, el cielo es el límite. Haz que tu negocio en línea se dispare y empieza
                  a
                  vender productos hoy mismo.</p>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>


        </div>
      </v-container>
    </v-sheet>

    <!-- region Price -->
    <!--    <v-sheet class="pt-10 pb-16 text-center" color="greyPrimary">-->
    <!--      <v-container>-->
    <!--        <p class="text-center text-button">Plan de Precios</p>-->
    <!--        <h2 class="text-center mb-1">Configura tu negocio y elige tu plan más tarde</h2>-->
    <!--        <p class="pb-5 text-body-1 font-weight-medium" style="color: #008081">Prueba SeOferta por 30 días, sin-->
    <!--          compromiso</p>-->
    <!--        <sign-up componentName="RegisterHome2"></sign-up>-->
    <!--        <div class="mt-16 mx-lg-16 px-lg-16">-->
    <!--          <v-row>-->
    <!--            <v-col cols="12" sm="6">-->
    <!--              <v-hover v-slot="{ hover }">-->
    <!--                <v-card :class="{ 'on-hover': hover }"-->
    <!--                        :elevation="hover ? 5: 2"-->
    <!--                        class="py-8 px-10 rounded-t-xl rounded-b-xl">-->

    <!--                  <p class="text-h4 font-weight-medium mb-5" style="color: black">Básico</p>-->
    <!--                  <v-img :src="require('../assets/startup.svg')"-->
    <!--                         contain-->
    <!--                         max-height="70">-->
    <!--                  </v-img>-->
    <!--                  <p class="mt-8 text-body-1">Ideal para negocios ocasionales y negocios nuevos en fase de-->
    <!--                    crecimiento</p>-->
    <!--                  <v-list dense>-->
    <!--                    <v-list-item class="px-0">-->
    <!--                      <v-list-item-icon>-->
    <!--                        <v-icon color="primary">mdi-check</v-icon>-->
    <!--                      </v-list-item-icon>-->
    <!--                      <v-list-item-titles class="text-left">Administrar un negocio</v-list-item-titles>-->
    <!--                    </v-list-item>-->
    <!--                    <v-divider></v-divider>-->
    <!--                    <v-list-item class="px-0">-->
    <!--                      <v-list-item-icon>-->
    <!--                        <v-icon color="primary">mdi-check</v-icon>-->
    <!--                      </v-list-item-icon>-->
    <!--                      <v-list-item-titles class="text-left">Productos ilimitados</v-list-item-titles>-->
    <!--                    </v-list-item>-->
    <!--                    <v-divider></v-divider>-->
    <!--                    <v-list-item class="px-0">-->
    <!--                      <v-list-item-icon>-->
    <!--                        <v-icon color="primary">mdi-check</v-icon>-->
    <!--                      </v-list-item-icon>-->
    <!--                      <v-list-item-titles class="text-left">Soporte 24/7</v-list-item-titles>-->
    <!--                    </v-list-item>-->
    <!--                    <v-divider></v-divider>-->
    <!--                    <v-list-item class="px-0" disabled>-->
    <!--                      <v-list-item-icon>-->
    <!--                        <v-icon color="error">mdi-close</v-icon>-->
    <!--                      </v-list-item-icon>-->
    <!--                      <v-list-item-titles class="text-left">Sin anuncios de publicidad</v-list-item-titles>-->
    <!--                    </v-list-item>-->
    <!--                    <v-divider></v-divider>-->
    <!--                    <v-list-item class="px-0" disabled>-->
    <!--                      <v-list-item-icon>-->
    <!--                        <v-icon color="error">mdi-close</v-icon>-->
    <!--                      </v-list-item-icon>-->
    <!--                      <v-list-item-titles class="text-left">Promoción</v-list-item-titles>-->
    <!--                    </v-list-item>-->
    <!--                    <v-divider></v-divider>-->
    <!--                    <v-list-item class="px-0" disabled>-->
    <!--                      <v-list-item-icon>-->
    <!--                        <v-icon color="error">mdi-close</v-icon>-->
    <!--                      </v-list-item-icon>-->
    <!--                      <v-list-item-titles class="text-left">Notificaciones</v-list-item-titles>-->
    <!--                    </v-list-item>-->
    <!--                    <v-divider></v-divider>-->
    <!--                  </v-list>-->
    <!--                  <v-sheet color="primaryDark" class="d-flex rounded-pill mt-3">-->
    <!--                    <v-list-item link dark>-->
    <!--                      <v-list-item-content class="font-weight-medium">-->
    <!--                        <v-list-item-title>Plan Gratis</v-list-item-title>-->
    <!--                        <v-list-item-subtitle style="font-size: 16px">$0 CUP</v-list-item-subtitle>-->
    <!--                      </v-list-item-content>-->
    <!--                    </v-list-item>-->
    <!--                  </v-sheet>-->
    <!--                </v-card>-->
    <!--              </v-hover>-->

    <!--            </v-col>-->
    <!--            <v-col cols="12" sm="6">-->
    <!--              <v-hover v-slot="{ hover }">-->
    <!--                <v-card :elevation="hover ? 12 : 2"-->
    <!--                        class="{ 'on-hover': hover } py-8 px-10 rounded-t-xl rounded-b-xl">-->
    <!--                  <p class="text-h4 font-weight-medium mb-5" style="color: black">Profesional</p>-->
    <!--                  <v-img :src="require('../assets/diamond-secondary.svg')"-->
    <!--                         contain-->
    <!--                         max-height="70">-->
    <!--                  </v-img>-->
    <!--                  <p class="mt-8 text-body-1">Para negocios ya consolidados y con experiencia del-->
    <!--                    funcionamiento-->
    <!--                    en línea</p>-->
    <!--                  <v-list dense>-->
    <!--                    <v-list-item class="px-0">-->
    <!--                      <v-list-item-icon>-->
    <!--                        <v-icon color="secondary">mdi-check</v-icon>-->
    <!--                      </v-list-item-icon>-->
    <!--                      <v-list-item-titles class="text-left">Administrar un negocio</v-list-item-titles>-->
    <!--                    </v-list-item>-->
    <!--                    <v-divider></v-divider>-->
    <!--                    <v-list-item class="px-0">-->
    <!--                      <v-list-item-icon>-->
    <!--                        <v-icon color="secondary">mdi-check</v-icon>-->
    <!--                      </v-list-item-icon>-->
    <!--                      <v-list-item-titles class="text-left">Productos ilimitados</v-list-item-titles>-->
    <!--                    </v-list-item>-->
    <!--                    <v-divider></v-divider>-->
    <!--                    <v-list-item class="px-0">-->
    <!--                      <v-list-item-icon>-->
    <!--                        <v-icon color="secondary">mdi-check</v-icon>-->
    <!--                      </v-list-item-icon>-->
    <!--                      <v-list-item-titles class="text-left">Soporte 24/7</v-list-item-titles>-->
    <!--                    </v-list-item>-->
    <!--                    <v-divider></v-divider>-->
    <!--                    <v-list-item class="px-0">-->
    <!--                      <v-list-item-icon>-->
    <!--                        <v-icon color="secondary">mdi-check</v-icon>-->
    <!--                      </v-list-item-icon>-->
    <!--                      <v-list-item-titles class="text-left pr-2">Sin anuncios de publicidad</v-list-item-titles>-->

    <!--                    </v-list-item>-->
    <!--                    <v-divider></v-divider>-->
    <!--                    <v-list-item class="px-0">-->
    <!--                      <v-list-item-icon>-->
    <!--                        <v-icon color="secondary">mdi-check</v-icon>-->
    <!--                      </v-list-item-icon>-->
    <!--                      <v-list-item-titles class="text-left">Promoción</v-list-item-titles>-->
    <!--                    </v-list-item>-->
    <!--                    <v-divider></v-divider>-->
    <!--                    <v-list-item class="px-0">-->
    <!--                      <v-list-item-icon>-->
    <!--                        <v-icon color="secondary">mdi-check</v-icon>-->
    <!--                      </v-list-item-icon>-->
    <!--                      <v-list-item-titles class="text-left">Notificaciones</v-list-item-titles>-->
    <!--                    </v-list-item>-->
    <!--                    <v-divider></v-divider>-->
    <!--                  </v-list>-->

    <!--                  <v-sheet color="secondary" class="d-flex rounded-pill mt-3">-->
    <!--                    <v-list-item link dark>-->
    <!--                      <v-list-item-content class="font-weight-medium">-->
    <!--                        <v-list-item-title>Mensual</v-list-item-title>-->
    <!--                        <v-list-item-subtitle style="font-size: 16px">$100 CUP</v-list-item-subtitle>-->
    <!--                      </v-list-item-content>-->
    <!--                    </v-list-item>-->
    <!--                    <v-divider class="my-btn-divider" vertical></v-divider>-->
    <!--                    <v-list-item link dark>-->
    <!--                      <v-list-item-content class="font-weight-medium">-->
    <!--                        <v-list-item-title>Anual</v-list-item-title>-->
    <!--                        <v-list-item-subtitle style="font-size: 16px">$1.000 CUP</v-list-item-subtitle>-->
    <!--                      </v-list-item-content>-->
    <!--                    </v-list-item>-->
    <!--                  </v-sheet>-->
    <!--                </v-card>-->
    <!--              </v-hover>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </div>-->
    <!--      </v-container>-->
    <!--    </v-sheet>-->
    <!-- endregion -->

    <!-- Messenger plugin de chat Code -->
    <div id="fb-root"></div>

    <!-- Your plugin de chat code -->

    <div id="fb-customer-chat" class="fb-customerchat"></div>

    <Footer></Footer>
  </div>
</template>

<script>
import services from "../services";
import {mapState} from "vuex"
import {getFirstChar} from "../utils/data";

const Toolbar = () => import(/* webpackPrefetch: true */ "../components/Toolbar.vue");
const SignUp = () => import(/* webpackPrefetch: true */ "../components/SignUp.vue");
const MenuApp = () => import(/* webpackPrefetch: true */ "../components/MenuApp.vue");
const SignIn = () => import(/* webpackPrefetch: true */ "../components/SignIn.vue");
const Footer = () => import(/* webpackPrefetch: true */ "../components/Footer");

export default {
  name: "Home",
  data: () => ({
    dialog: false,
    value: 1,
    icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward'],
    items: [
      {
        title: 'Restaurante',
        img: 'https://images.unsplash.com/photo-1429514513361-8fa32282fd5f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3264&q=80',
      },
      {
        title: 'Cafetería',
        img: 'https://images.unsplash.com/photo-1498038432885-c6f3f1b912ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80',
      },
      {
        title: 'Tienda de Ropa',
        img: 'https://images.unsplash.com/photo-1542320868-f4d80389e1c4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3750&q=80',
      },
    ],
    transparent: 'rgba(255, 255, 255, 0)',
    promotedBusiness: []
  }),
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    },
    ...mapState(['snackbar']),
    isLogged() {
      return this.$store.state.auth.loggedIn;
    },
    step() {
      return this.isLogged ? 2 : 1;
    },
  },
  methods: {
    firstChar: getFirstChar,
    getBusinessUrl: function (slug) {
      return window.location.origin + "/" + slug;
    }
  },
  components: {
    SignIn,
    Toolbar,
    MenuApp,
    Footer,
    SignUp,
  },
  filters: {
    truncate: function (value, length, suffix) {
      if (value.length > length) {
        let trimmedString = value.substr(0, length);
        return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + suffix;
      } else {
        return value;
      }
    },
  },
  mounted() {
  },
  updated() {
    let chatbox = document.getElementById('fb-customer-chat');
    chatbox.setAttribute("page_id", "103589962035537");
    chatbox.setAttribute("attribution", "biz_inbox");

    window.fbAsyncInit = function () {
      FB.init({
        xfbml: true,
        version: 'v11.0'
      });
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  },
  created() {
    services
        .business
        .getPromotedBusiness({rand: true})
        .then((result) => {
          this.promotedBusiness = result.data || [];
        })
        .catch((error) => {
          console.log(error);
        })
  },
  metaInfo() {
    return {
      title: "Inicio - SeOferta.NET",
      meta: [
        {
          name: 'description',
          content: 'SeOferta.net es la mejor plataforma para dar a conocer tus servicios y productos a tus clientes.'
        },
      ],
    }
  },
};
</script>

<style scoped>
.v-card {
  transition: all .3s cubic-bezier(0, 0, .5, 1);
}

.v-card.on-hover {
  transform: scale(1.01);
}

.v-chip-text {
  font-weight: 500;
}

.theme--light.v-chip:not(.v-chip--active) {
  background: #e9f8f5 !important;
}

.v-stepper__step {
  padding: 24px 0px;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.iframe-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.my-link-hover h3:hover, a:hover {
  color: #008081;
}

.my-btn-divider {
  border-color: #f5ddcc;
}

</style>
<style>
.pepe {
  justify-content: center;
}

.pepe .v-slide-group__wrapper {
  flex: unset !important;
}
</style>
